import axios from './index';
import endpointGetAlert from '@/endpoints/endpointGetAlert';
import endpointPostActionCreate from '@/endpoints/endpointPostActionCreate';

export async function fetchAlert(id: number) {
    return await axios[endpointGetAlert.method](endpointGetAlert.url.replace(':alert', id.toString()));
}

export async function postAction(alertId: number, actionConfigurationId: number, comment: string | null) {
    return await axios[endpointPostActionCreate.method](
        endpointPostActionCreate.url
            .replace(':alert', alertId.toString())
            .replace(':actionConfiguration', actionConfigurationId.toString()),
        {
            comment,
        }
    );
}
